.question-profile-homepage {
    width: 100%;
    overflow-y: hidden;
}

.question-profile-container {
    height: 100%;
}

#profiles_list {
    list-style: none;
    padding: 0;
    border: 1px solid #efecec;
    flex-grow: 1;
    overflow: auto;
}

#profiles_list p {
    margin-bottom: 4.5px;
}

#profiles_list > li {
    padding: 10px;
    border-top: 1px solid #efecec;
    cursor: pointer;
}

#profiles_list > li:first-child {
    border-top: none;
}

#profiles_list > li div {
    position: relative;
}

#profiles_list li:hover {
    background-color: #e7e8e8;
}

#profiles_list li.selected {
    background-color: #f5f5f5;
    border-left: 5px #5a3ff5 solid;
}

#profiles_list li.selected .subtitle {
    color: #9c9a9a;
}

.profile-heading .profile-name {
    margin-top: 10px;
}

.title-header {
    font-size: 0.9em;
    font-weight: 500;
    color: #3b73af;
}

.subtitle {
    font-size: 0.8em;
    font-weight: 400;
    color: #9c9a9a;
}

/* #profiles_questions {
    height: calc(100% - 60px);
    border: 1px solid #eee;
    padding-left: 0;
    margin-left: -16px;
    overflow-y: auto;
    background-color: #f5f5f5;
    border-left: none;
    padding-bottom: 20px;
} */

#profiles_questions.highlight-class {
    pointer-events: none;
}

#profiles {
    height: calc(100% - 60px);
    /* overflow-y: auto; */
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.message-info-well {
    margin-top: 15px;
    text-align: center;
}

.message-info-well .well {
    background-color: #fff;
}

.message-info-well p {
    margin: 10px 0;
}

@media (max-width: 767px) {
    .question-profile-homepage {
        overflow-y: auto;
    }

    #profiles_questions {
        margin-left: 15px;
        margin-right: 15px;
        width: -webkit-fill-available;
    }
}

#profiles_questions .profile-heading {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
}

#questions_list {
    list-style: none;
    padding-left: 15px;
}

.delete-profile {
    margin-left: 10px;
    cursor: pointer;
}

.delete-profile:hover {
    color: red;
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Profiles - Create New Profile
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.add-profile-item {
    padding: 0 5px;
}

#add-profile-item-container.highlight-class {
    height: 250px;
    padding: 10px;
}

#add-profile-item-container.highlight-class .question-delete {
    pointer-events: none;
}

.profile-btn button {
    width: 100%;
}

#profile_loading {
    margin-bottom: 5px;
}

.saving {
    font-weight: 300;
}

.profile-saved {
    text-align: center;
}

.profile-saved i {
    color: #0cc30c;
    font-size: 2em;
    border-radius: 50%;
    border: 2px solid #0cc30c;
    padding: 10px;
}

.profile-saved-message {
    text-align: center;
    margin-top: 15px;
    font-size: 0.9em;
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Questions - Custom
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.answer-btns,
.answer-btns-container {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.answer-btns button {
    width: 100%;
    padding: 10px 5px;
    font-size: 0.9em;
    text-transform: capitalize;
}

.answer-btns.active .type-dropdown {
    outline: none;
    background-color: #4867fb;
    border-color: #7289f9;
    box-shadow: 0 0 25px #7289f9;
    color: white;
}

.answer-btns.active .type-text {
    outline: none;
    background-color: #402c4c;
    border-color: #8f40c1;
    box-shadow: 0 0 25px #693588;
    color: white;
}

.answer-btns.active .type-multitext {
    outline: none;
    background-color: #ff2f2f;
    border-color: #f95959;
    box-shadow: 0 0 25px #f95959;
    color: white;
}

.answer-btns.active .type-number {
    outline: none;
    background-color: #f9961d;
    border-color: #fba843;
    box-shadow: 0 0 25px #fba843;
    color: white;
}

.answers-container {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
}

.add-answer-container {
    padding: 15px 15px 0 15px;
}

.add-answer-text {
    padding-left: 0;
}

.remove-answer {
    left: 3px;
    top: 2px;
    cursor: pointer;
}

.add-question-row {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    border-top: 1px solid #eee;
}

.add-question-btn {
    float: right;
    margin-top: 10px;
    background-color: #2d9eff;
    border-color: #2d9eff;
    color: white;
}

.saving-question {
    float: right;
    margin-top: 10px;
}

.add-question-btn:hover {
    background-color: #1c81d8;
    border-color: #1c81d8;
}

.required-container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;

    input[type='checkbox'] {
        margin: 0;
    }
}

.required-container label {
    margin: auto;
    display: table;
    margin-left: 2px;
    font-size: 15px;
}

.requiredInput {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}

.saving-question .profile-saved i {
    padding: 5px;
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Questions - Static
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.static-question-box .thumbnail,
.static-question-box .caption {
    padding-top: 0;
    padding-bottom: 0;
}

#new-question-item.highlight-class .add-question-row,
#new-question-item.highlight-class .glyphicon {
    pointer-events: none;
}

/* addQuestionBtn */

.static-question-box .thumbnail {
    margin-bottom: 12px;
    margin-top: 12px;
}

.static-question-box .drag-n-drop-icon {
    display: none;
}

.static-question-box:hover .drag-n-drop-icon {
    display: block;
}

.question-header {
    display: inline-block;
}

.answer-type {
    padding: 3px 15px;
    background-color: aquamarine;
    color: #2c3e50;
    border-radius: 15px;
    width: auto;
    margin: auto;
    float: right;
    margin: 5.5px 0;
    text-transform: capitalize;
}

.type-text {
    background-color: #402c4c;
    border-color: #8f40c1;
    color: white;
}

.type-multitext {
    background-color: #f95959;
    border-color: #f95959;
    color: white;
}

.type-dropdown {
    background-color: #7289f9;
    border-color: #7289f9;
    color: white;
}

.type-number {
    background-color: #fba843;
    border-color: #fba843;
    color: white;
}

.question-title {
    border-bottom: 1px solid #eee;
}

.question-col {
    border-right: 1px solid #eee;
}

.question-body .question-col,
.question-body .additional-info-col {
    padding: 10px;
    min-height: 60px;
}

.question-text {
    font-size: 1em;
    font-style: italic;
}

.additional-question-info {
    margin-top: 10px;
}

.answer-badge {
    display: inline-block;
    margin-right: 4px;
}

.question-delete {
    position: absolute;
    right: 8px;
    top: -5px;
    font-size: 1.3em;
    color: red;
    z-index: 8;
}

.question-delete:hover {
    cursor: pointer;
    zoom: 1.1;
}

.add-profile-item .question-delete {
    right: 2px;
    top: -8px;
    background-color: white;
}

.delete-question div {
    margin-bottom: 10px;
}

.cancel-question-btn {
    margin-right: 5px;
}
.delete-question-btn {
    margin-left: 5px;
}

@media (max-width: 400px) {
    .additional-info-col h5 {
        margin-left: -8px;
    }
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Questions - Errors
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.add-question-row .message-container {
    padding-left: 0;
    padding-right: 0;
    margin-top: 10px;
}

.add-question-row .message-container .alert {
    margin-bottom: 0;
    margin-right: 25px;
    padding: 11px;
}

@media (max-width: 768px) {
    .add-question-row .message-container .alert {
        margin-right: 0;
    }
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Profile - Delete
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.delete-profile-container {
    left: 50%;
    top: 8%;
    transform: translate(-50%);
    z-index: 30;
    position: fixed;
    padding: 2em;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.delete-profile-panel {
    z-index: 50;
}

.delete-profile-panel .panel-body {
    text-align: center;
}

.cancel-profile-btn {
    margin-right: 5px;
}

.delete-profile-btn {
    margin-left: 5px;
}

.delete-profile-container .message-container .alert-success {
    margin-top: 5px;
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Profile - Mobile
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.profile-select {
    margin-bottom: 5px;
}

@media (max-width: 767px) {
    #profiles {
        height: unset;
    }

    .profile-btn,
    .profile-select,
    .profile-dropdown label,
    .add-profile-item {
        padding-left: 0;
        padding-right: 0;
    }

    .add-profile-item {
        border-top: 1px solid #ccc;
        padding-top: 5px;
    }

    .add-profile-item .question-delete {
        right: -7px;
        top: 0px;
    }
}

@media (max-width: 600px) {
    .delete-profile-panel {
        top: 45%;
    }
}
